<template>
  <div class="container-xl" v-if="!$store.getters.isForbidden">
    <div class="card">
      <div class="card-header justify-content-between">
        <div class="card-title">{{ $route.name }} Product List</div>
      </div>
      <div class="card-body">
        <div class="flex">
          <div class="w-1/3">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Filter type">
                <el-select size="small" v-model="filter.search_by">
                  <el-option v-for="(item, i) in search_options" :key="i" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Search Keyword">
                <el-input clearable @clear="searchHandler" size="small" type="text" v-model="filter.search_keyword" placeholder="Input search keyword" class="mr-2"/>
              </el-form-item>
            </el-form>
          </div>
          <div class="w-1/3">
            <el-form ref="form" label-width="120px">
              <el-form-item label="Status">
                <el-select size="small" v-model="filter.status_filter">
                  <el-option v-for="(item, i) in status_options" :key="i" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-button type="primary" @click="searchHandler" size="small"><font-awesome-icon icon="search"/> Search</el-button>
      </div>
      <div class="table-responsive">
        <table class="table card-table text-nowrap table-vcenter">
          <thead>
            <tr>
              <th>Product Name</th>
              <th width="120">Product Type</th>
              <th width="180">Assigned Biller</th>
              <th width="180">Biller Product Code</th>
              <th width="150">Biller Price</th>
              <th width="150">Sell Price</th>
              <th>Status</th>
              <th width="100">#</th>
            </tr>
          </thead>
          <tbody v-loading="loading.page">
            <tr v-for="item in list" :key="item.id">
              <td>{{ item.denom_name }}</td>
              <td>{{ item.biller_type?.toUpperCase() || '-' }}</td>
              <td>{{ item.active_biller_name }}</td>
              <td>{{ item.active_biller_product_code }}</td>
              <td>{{ item.biller_sell_price_str }}</td>
              <td>{{ item.sell_price_str }}</td>
              <td>
                <span class="tag" :class="item.status_color">{{ item.status_str }}</span>
              </td>
              <td>
                <el-dropdown @command="handleCommand($event, item)" size="mini" type="info">
                  <el-button size="mini" type="info">
                    Action&nbsp;<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown" class="px-2 py-2">
                    <!-- <el-dropdown-item class="py-1" command="handlerBtnEditProduct">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="edit"/></span>
                        <span>Edit Product</span>
                      </div>
                    </el-dropdown-item> -->
                    <el-dropdown-item class="py-1" command="handlerBtnSwitchBiller">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="exchange-alt"/></span>
                        <span>Switch Biller</span>
                      </div>
                    </el-dropdown-item>
                    <el-dropdown-item class="py-1" command="handlerBtnActivateProduct">
                      <div class="flex">
                        <span style="width: 20px"><font-awesome-icon icon="power-off"/></span>
                        <span v-if="item.status == 0">Activate Product</span>
                        <span v-if="item.status == 1">Deactivate Product</span>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pagination
        @pageChange="pageChangeHandler"
        :page="filter.page"
        :list="list"
        :per_page="10"
        :total_rows="total_rows"></pagination>
      <switch-biller :isShown="modal.switch_biller" :selectedProduct="selected_product" @onSubmit="updateProduct" @onHide="modal.switch_biller = false"/>
    </div>
  </div>
</template>
<script>
/* eslint-disable radix */
import { isEqual, isEmpty, clone } from 'lodash';
import {
  GET_PPOB_LIST, UPDATE_PPOB, ENABLE_DISABLE_PPOB,
} from '../../store/modules/ppob';
import Pagination from '../components/Pagination.vue';
import SwitchBiller from './ppob/SwitchBiller.vue';

export default {
  name: 'PPOB',
  metaInfo: {
    title: 'PPOB',
  },
  data() {
    return {
      loading: {
        page: false,
      },
      filter: {
        page: 1,
        search_by: 'denom_name',
        search_keyword: '',
        status_filter: '',
      },
      list: [],
      total_rows: 0,
      search_options: [{
        value: 'denom_name',
        label: 'Product Name',
      }],
      modal: {
        switch_biller: false,
      },
      status_options: [
        {
          value: '',
          label: 'All',
        },
        {
          value: 1,
          label: 'Active',
        },
        {
          value: 0,
          label: 'Inactive',
        },
      ],
      selected_product: {},
    };
  },
  async mounted() {
    this.$parent.showLoader();
    if (!isEmpty(this.$route.query)) {
      this.filter = clone(this.$route.query);
    }
    await this.getList();
    this.$parent.hideLoader();
  },
  methods: {
    updateURLQuery() {
      if (!isEqual(this.$route.query, this.filter)) {
        this.filter.page = parseInt(this.filter.page);
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.filter,
          },
        }).catch(() => {});
      }
    },
    pageChangeHandler(page) {
      this.filter.page = page;
      this.updateURLQuery();
    },
    async getList() {
      this.loading.page = true;
      await this.$store.dispatch(GET_PPOB_LIST, this.filter);
      this.updateURLQuery();
      ({ rows: this.list, count: this.total_rows } = this.$store.getters.ppob);
      this.loading.page = false;
    },
    searchHandler() {
      this.filter.page = 1;
      this.updateURLQuery();
      this.getList();
    },
    handleCommand(fn_name, item) {
      this[fn_name](item);
    },
    handlerBtnSwitchBiller(item) {
      // console.log(item);
      this.selected_product = item;
      this.modal.switch_biller = true;
    },
    handlerBtnEditProduct(item) {
      console.log(item);
    },
    handlerBtnActivateProduct(item) {
      console.log(item, ENABLE_DISABLE_PPOB, !item.status);
      this.$confirm(`Are you want to ${!item.status ? 'enable' : 'disable'} this product?`, {
        type: 'info',
        title: 'Confirmation',
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await this.$store.dispatch(ENABLE_DISABLE_PPOB, {
              id: item.id,
              status: !item.status ? 'active' : 'inactive',
            }).catch(() => {});
            instance.confirmButtonLoading = false;
            this.getList();
          }
          done();
        },
      }).catch(() => {});
    },
    async updateProduct({ id, data } = {}) {
      this.$parent.showLoader();
      await this.$store.dispatch(UPDATE_PPOB, { id, data });
      this.$parent.hideLoader();
      this.getList();
    },
  },
  components: {
    Pagination,
    SwitchBiller,
  },
};
</script>